import React from 'react'
import {
    useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Content from "../../components/articles-content"

const ArticlesPage = () => {
    const {t} = useI18next();
    return (
        <Layout>
            <SEO title={t("MENU.ARTICLES-WHITE-PAPERS")} />
            <Content />
        </Layout>
    )
}

export default ArticlesPage